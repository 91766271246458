// By its very nature, immer uses param reassignment, so:
/* eslint-disable no-param-reassign */
import create from 'zustand';
import produce, { enableMapSet } from 'immer';
import { activateUserSocketHandlers } from '../socket-handlers';
import updateProgressFromCompletedQuiz from '../helpers/quiz-progress';

// Extend immer to work with Maps and Sets.
enableMapSet();

function getDefaultGoalProgressState(goalId) {
  return {
    goalId,
    myGoal: {},
    readiness: {},
    learn: {
      videos: {},
    },
    do: {
      videos: {},
    },
    quiz: {},
    remember: {},
  };
}

export default create((set, get) => {
  // Replace zustand's set fn with a version that
  // uses immer first to get the next state to set.
  const immerSet = (fn) => set(produce(fn));

  return {
    setSocketOnConnectData: (data) => {
      const {
        goalsProgress,
        diagnosticProgress, // Only diagnosticProgress.quizAttempts consumed, and only by universe
        pupils,
        completedGoals,
        goalsEncountered,
        previewChild,
      } = data;
      if (goalsProgress) set({ goalsProgress });
      if (diagnosticProgress) set({ diagnosticProgress });
      if (pupils) {
        const pupilsGoalsProgress = {};
        pupils.forEach((pupil) => {
          pupilsGoalsProgress[pupil.id] = pupil.goalsProgress;
        });
        set({ pupilsGoalsProgress });
      }
      if (completedGoals) {
        set({ completedGoals });
      }
      if (goalsEncountered) {
        set({ goalsEncountered });
      }
      if (previewChild) {
        get().setFromPupilData(previewChild);
      }
    },
    activateActionHandlers: (actions, socket) => {
      return activateUserSocketHandlers(actions, get, socket);
    },
    setPupilsProgress: (pupilsProgress) => {
      const pupilsGoalsProgress = {};
      Object.entries(pupilsProgress).forEach(([id, pupilProgress]) => {
        const { goalsProgress = {} } = pupilProgress;
        pupilsGoalsProgress[id] = goalsProgress;
      });
      set({ pupilsGoalsProgress });
    },
    addQuizAttempt: (goalId, step, attempt) =>
      immerSet((draft) => {
        const goalProgress =
          draft.goalsProgress[goalId] || getDefaultGoalProgressState(goalId);

        if (!attempt.completionDate) {
          const progress = goalProgress[step];
          progress.inProgressQuizAttemptId = attempt.id;
        }
      }),

    getQuizAttempt: (goalId, step) => {
      const { goalsProgress } = get();
      return goalsProgress[goalId][step].quizAttempt;
    },
    answerQuestion: (goalId, step, attempt, answer) =>
      immerSet((draft) => {
        if (step === 'readiness') {
          const goalProgress =
            draft.goalsProgress[goalId] || getDefaultGoalProgressState(goalId);

          const progress = goalProgress[step];
          // One corrent answer defines if quiz is classed as attempted
          if (attempt.type === 'SPECIFIC_READINESS') {
            progress.specificQuizAttempted ||= answer.isCorrectAnswer;
          } else {
            progress.generalQuizAttempted ||= answer.isCorrectAnswer;
          }
        }
      }),

    completeQuizAttempt: (goalId, step, attempt) =>
      immerSet((draft) => {
        const goalProgress =
          draft.goalsProgress[goalId] || getDefaultGoalProgressState(goalId);
        const progress = goalProgress[step];

        updateProgressFromCompletedQuiz(progress, attempt, goalId, step);
      }),

    addDiagnosticQuizAttempt: (attempt) =>
      immerSet((draft) => {
        let { diagnosticProgress } = draft;

        diagnosticProgress ??= {};
        diagnosticProgress.quizAttempts ??= [];
        diagnosticProgress.quizAttempts.push({ ...attempt });
      }),
    syncDiagnosticAnswers: (attemptId, answers) =>
      immerSet((draft) => {
        const { quizAttempts } = draft.diagnosticProgress;
        const attempt = quizAttempts?.find(({ id }) => id === attemptId);
        if (attempt) attempt.answers = answers;
      }),
    answerDiagnosticQuestion: (quizAttemptId, answer) =>
      immerSet((draft) => {
        const { diagnosticProgress } = draft;

        const quizAttempt = diagnosticProgress?.quizAttempts?.find(
          (attempt) => attempt.id === quizAttemptId
        );
        if (quizAttempt) {
          (quizAttempt.answers ??= []).push(answer);
        }
      }),

    resetDiagnosticProgress: () =>
      immerSet((draft) => {
        const { diagnosticProgress } = draft;

        if (diagnosticProgress) {
          diagnosticProgress.quizAttempts = [];
        }
      }),

    setVideoProgress: (goalId, step, videoId, videoProgress) =>
      immerSet((draft) => {
        const goalProgress =
          draft.goalsProgress[goalId] ?? getDefaultGoalProgressState(goalId);

        goalProgress[step] ??= {};
        goalProgress[step].videos ??= {};
        goalProgress[step].videos[videoId] = videoProgress;

        draft.goalsProgress[goalId] = goalProgress;
      }),

    updateMyGoalNavProgress: (goalId, date) => {
      set((state) => {
        const goalsProgress = { ...state.goalsProgress };
        const goalProgress =
          state.goalsProgress[goalId] ?? getDefaultGoalProgressState(goalId);
        const myGoal = { lastNav: date };
        goalsProgress[goalId] = { ...goalProgress, myGoal };

        return { goalsProgress };
      });
    },
    updateReadinessNavProgress: (goalId, date) => {
      set((state) => {
        const goalsProgress = { ...state.goalsProgress };
        const goalProgress =
          state.goalsProgress[goalId] ?? getDefaultGoalProgressState(goalId);
        const readiness = {
          ...goalsProgress[goalId]?.readiness,
          lastNav: date,
        };
        goalsProgress[goalId] = { ...goalProgress, readiness };
        return { goalsProgress };
      });
    },
    setGoalCompleted: (goalId, completionDate) =>
      immerSet((draft) => {
        const existingDate = draft.completedGoals[goalId];
        draft.completedGoals[goalId] = existingDate ?? completionDate;
      }),
    setFromPupilData: (pupil) =>
      immerSet((draft) => {
        draft.goalsProgress = pupil.goalsProgress;
        draft.diagnosticProgress = pupil.diagnosticProgress;
        draft.completedGoals = pupil.completedGoals;
        draft.goalsEncountered = pupil.goalsEncountered;
      }),
    updateGoalsEncountered: (goalId, goalEncountered) =>
      immerSet((draft) => {
        draft.goalsEncountered[goalId] = goalEncountered;
      }),
    goalsProgress: {},
    diagnosticProgress: {},
    completedGoals: {},
    goalsEncountered: {},
    clear: () =>
      set({
        goalsProgress: {},
      }),
  };
});
