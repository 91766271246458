import updateProgressFromCompletedQuiz from '../helpers/quiz-progress';

/* eslint-disable no-param-reassign */
const getInitialState = () => ({
  revision: null,
  myRevision: [],
  previewRevisionId: undefined,
});

function getDefaultRevisionGoalProgressState(goalId) {
  return {
    goalId,
    myGoal: {},
    readiness: {},
    learn: {
      videos: {},
    },
    do: {
      videos: {},
    },
    quiz: {},
    remember: {},
  };
}

const revisionSlice = (set, get) => ({
  sliceName: 'revision',
  getInitialState,
  onConnected: (data) => {
    const { myRevision } = data;
    const { setMyRevision } = get();
    if (myRevision) {
      setMyRevision(myRevision);
    }
  },
  selectors: {
    ...getInitialState(),

    getRevision: (revisionId) =>
      get().myRevision.find((rev) => rev.id === revisionId),
  },
  mutators: {
    setMyRevision: (myRevision) =>
      set((draft) => {
        draft.myRevision = myRevision;
      }),
    setRevisionGoals: (revisionId, goals) =>
      set((draft) => {
        draft.myRevision = draft.myRevision.map((revision) => {
          if (revision.id === revisionId) {
            return { ...revision, goals };
          }
          return revision;
        });
      }),
    setRevisionGoalsProgress: (revisionId, goalsProgress) =>
      set((draft) => {
        draft.myRevision = draft.myRevision.map((revision) => {
          if (revision.id === revisionId) {
            return { ...revision, goalsProgress };
          }
          return revision;
        });
      }),
    setRevisionVideoProgress: (
      goalId,
      step,
      videoId,
      videoProgress,
      revisionId
    ) =>
      set((draft) => {
        const revision = draft.myRevision.find((rev) => rev.id === revisionId);
        if (revision) {
          const goalProgress =
            revision.goalsProgress[goalId] ??
            getDefaultRevisionGoalProgressState(goalId);

          goalProgress[step] ??= {};
          goalProgress[step].videos ??= {};
          goalProgress[step].videos[videoId] = videoProgress;

          revision.goalsProgress[goalId] = goalProgress;
        }
      }),
    addRevisionQuizAttempt: (goalId, step, attempt, revisionId) =>
      set((draft) => {
        const revision = draft.myRevision.find((rev) => rev.id === revisionId);
        if (revision) {
          const goalProgress =
            revision.goalsProgress[goalId] ??
            getDefaultRevisionGoalProgressState(goalId);

          if (!attempt.completionDate) {
            goalProgress[step] ??= {};
            goalProgress[step].inProgressQuizAttemptId = attempt.id;
          }
          revision.goalsProgress[goalId] = goalProgress;
        }
      }),
    completeRevisionQuizAttempt: (goalId, step, revisionId) =>
      set((draft) => {
        const { currentQuizAttempt, myRevision } = draft;
        currentQuizAttempt.completionDate = new Date().toJSON();

        const revision = myRevision.find((rev) => rev.id === revisionId);
        const goalsProgress = revision?.goalsProgress ?? {};
        const goalProgress = goalsProgress[goalId];
        const progress = goalProgress[step];

        updateProgressFromCompletedQuiz(
          progress,
          currentQuizAttempt,
          goalId,
          step
        );
      }),
    addRevisionAssignment: (revision) =>
      set((draft) => {
        draft.myRevision = [
          ...draft.myRevision.filter((rev) => rev.id !== revision.id),
          revision,
        ].filter((rev) => !rev.isUnassigned);
      }),
    getNextAndPrevGoalInRevision: (goalId, revisionId) => {
      if (!revisionId) return {};
      const { getRevision } = get();
      const { revisionGoals, goals } = getRevision(revisionId) ?? {};

      const currGoalIx = revisionGoals.findIndex(
        (goal) => goal.goalId === goalId
      );

      let ideaIdOfNextGoal;
      let ideaIdOfPrevGoal;

      const nextGoalIdInRevision = revisionGoals[currGoalIx + 1]?.goalId;
      if (nextGoalIdInRevision)
        ideaIdOfNextGoal = goals.find(
          (goal) => goal.id === nextGoalIdInRevision
        ).ideaId;

      const prevGoalIdInRevision = revisionGoals[currGoalIx - 1]?.goalId;
      if (prevGoalIdInRevision)
        ideaIdOfPrevGoal = goals.find(
          (goal) => goal.id === prevGoalIdInRevision
        ).ideaId;

      return {
        nextGoalIdInRevision,
        ideaIdOfNextGoal,
        prevGoalIdInRevision,
        ideaIdOfPrevGoal,
      };
    },
  },
});

export default revisionSlice;
