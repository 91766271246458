/* eslint-disable no-param-reassign */
const updateProgressFromCompletedQuiz = (
  progress,
  quizAttempt,
  goalId,
  step
) => {
  const { percentage, correctForGoal } = quizAttempt.score;
  progress.inProgressQuizAttemptId = null;
  progress.numCompletedQuizzes = (progress.numCompletedQuizzes ?? 0) + 1;
  progress.topScore = Math.max(progress.topScore ?? 0, percentage);
  progress.lastScore = percentage;
  progress.rememberMarksAchieved = Math.max(
    progress.rememberMarksAchieved ?? 0,
    correctForGoal
  );
  progress.lastResult = {
    numCorrect: quizAttempt.answers.filter((a) => a.isCorrectAnswer).length,
    numIncorrect: quizAttempt.answers.filter(
      (a) => !a.isCorrectAnswer && a.answerIndex !== -1
    ).length,
    numSkipped: quizAttempt.answers.filter((a) => a.answerIndex === -1).length,
    numCorrectForGoal: quizAttempt.answers.filter(
      (a) => a.isCorrectAnswer && a.goalId === goalId
    ).length,
  };
  if (step === 'readiness') {
    if (quizAttempt.type === 'SPECIFIC_READINESS') {
      progress.specificQuizCompleted = true;
    } else {
      progress.generalQuizCompleted = true;
    }
  }
};

export default updateProgressFromCompletedQuiz;
